// ** React Imports
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

// ** Data Slices
import guardSlice from './guard'
import userSlice from './user'
import organizationsSlice from './organizations'
import projectsSlice from './projects'
import invitationsSlice from './invitations'

// ** UI Slices
import workspaceSlice from './ui/widgets/workspace'
import primaryAppBarSlice from './ui/widgets/primary-app-bar'
import snackbarSlice from './ui/widgets/snackbar'
import createProjectViewSlice from './ui/views/create-project'
import projectInfoViewSlice from './ui/views/project-info'
import projectUsersViewSlice from './ui/views/project-users'
import organizationUsersViewSlice from './ui/views/organization-users'
import serverSideTrackingSlice from './ui/views/server-side-tracking'
import dataMappingSlice from './data-mapping'
import trafficAttributionTableConfigSlice from './reports/traffic-attribution-table'
import trafficAttributionTableToolbarSlice from './ui/widgets/traffic-attriubution-table-toolbar'
import eventsTableConfigSlice from './reports/events-table'
import eventsTableToolbarSlice from './ui/widgets/events-table-toolbar'
import dealsTableConfigSlice from './reports/deals-table'
import dealsTableToolbarSlice from './ui/widgets/deals-table-toolbar'
import ordersTableConfigSlice from './reports/orders-table'
import ordersTableToolbarSlice from './ui/widgets/orders-table-toolbar'
import adChannelsTableConfigSlice from './reports/ad-channels-table'
import adChannelsTableToolbarSlice from './ui/widgets/ad-channels-table-toolbar'
import createFacebookAdsCostExportJobSlice from './ui/widgets/create-facebook-ads-cost-export-job'
import facebookAdsCostExportConnectionDialogSlice from './ui/widgets/facebook-ads-cost-export-connection-dialog'
import facebookAdsCostExportJobDialogSlice from './ui/widgets/facebook-ads-cost-export-job-dialog'
import createGoogleAdsCostExportJobSlice from './ui/widgets/create-google-ads-cost-export-job'
import googleAdsCostExportJobDialogSlice from './ui/widgets/google-ads-cost-export-job-dialog'
import googleAdsCostExportConnectionDialogSlice from './ui/widgets/google-ads-cost-export-connection-dialog'
import paramDefinitionsWidgetsSlice from './ui/widgets/param-definitions-widgets'
import metricDefinitionsWidgetsSlice from './ui/widgets/metric-definitions-widgets'
import calculatedColumnMetricConstructorSlice from './reports/calculated-column-metric-constructor'
import calculatedColumnMetricConstructorFilterWidgetSlice from './ui/widgets/calculated-column-metric-constructor-filter'
import measureMetricConstructorSlice from './reports/measure-metric-constructor'
import measureMetricConstructorValidationSlice from './ui/widgets/measure-metric-constructor-validation'
import createTikTokAdsCostExportJobSlice from './ui/widgets/create-tiktok-ads-cost-export-job'
import tiktokAdsCostExportJobDialogSlice from './ui/widgets/tiktok-ads-cost-export-job-dialog'
import tiktokAdsCostExportConnectionDialogSlice from './ui/widgets/tiktok-ads-cost-export-connection-dialog'
import externalDashboardSlice from './reports/external-dashboard'

// ** API Slice
import { apiSlice } from '../api'

const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: ['primaryAppBar'],
}

const userPersistConfig = {
  key: 'user',
  storage,
  whitelist: [
    'isAuth',
    'id',
    'firstName',
    'lastName',
    'email',
    'phone',
    'accessToken',
    'isEmailConfirmed',
  ],
}

const organizationsPersistConfig = {
  key: 'organizations',
  storage,
}

const projectsPersistConfig = {
  key: 'projects',
  storage,
}

const rootReducer = combineReducers({
  guard: guardSlice,
  user: persistReducer(userPersistConfig, userSlice),
  organizations: persistReducer(organizationsPersistConfig, organizationsSlice),
  projects: persistReducer(projectsPersistConfig, projectsSlice),
  invitations: invitationsSlice,
  workspace: workspaceSlice,
  primaryAppBar: primaryAppBarSlice,
  snackbar: snackbarSlice,
  createProjectView: createProjectViewSlice,
  organizationUsersView: organizationUsersViewSlice,
  projectInfoView: projectInfoViewSlice,
  projectUsersView: projectUsersViewSlice,
  dataMapping: dataMappingSlice,
  serverSideTracking: serverSideTrackingSlice,
  trafficAttributionTableConfig: trafficAttributionTableConfigSlice,
  trafficAttributionTableToolbar: trafficAttributionTableToolbarSlice,
  eventsTableConfig: eventsTableConfigSlice,
  eventsTableToolbar: eventsTableToolbarSlice,
  dealsTableConfig: dealsTableConfigSlice,
  dealsTableToolbar: dealsTableToolbarSlice,
  ordersTableConfig: ordersTableConfigSlice,
  ordersTableToolbar: ordersTableToolbarSlice,
  adChannelsTableConfig: adChannelsTableConfigSlice,
  adChannelsTableToolbar: adChannelsTableToolbarSlice,
  createFacebookAdsCostExportJob: createFacebookAdsCostExportJobSlice,
  facebookAdsCostExportConnectionDialog: facebookAdsCostExportConnectionDialogSlice,
  facebookAdsCostExportJobDialog: facebookAdsCostExportJobDialogSlice,
  createGoogleAdsCostExportJob: createGoogleAdsCostExportJobSlice,
  googleAdsCostExportJobDialog: googleAdsCostExportJobDialogSlice,
  googleAdsCostExportConnectionDialog: googleAdsCostExportConnectionDialogSlice,
  paramDefinitionsWidgets: paramDefinitionsWidgetsSlice,
  metricDefinitionsWidgets: metricDefinitionsWidgetsSlice,
  calculatedColumnMetricConstructor: calculatedColumnMetricConstructorSlice,
  calculatedColumnMetricConstructorFilterWidget: calculatedColumnMetricConstructorFilterWidgetSlice,
  measureMetricConstructor: measureMetricConstructorSlice,
  measureMetricConstructorValidation: measureMetricConstructorValidationSlice,
  createTikTokAdsCostExportJob: createTikTokAdsCostExportJobSlice,
  tiktokAdsCostExportJobDialog: tiktokAdsCostExportJobDialogSlice,
  tiktokAdsCostExportConnectionDialog: tiktokAdsCostExportConnectionDialogSlice,
  externalDashboardConfig: externalDashboardSlice,
  [apiSlice.reducerPath]: apiSlice.reducer,
})

const persistedReducer = persistReducer(rootPersistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          'trafficAttributionTableToolbar/openFilterConditionMenu',
          'trafficAttributionTableToolbar/openLegendMetricMenu',
          'eventsTableToolbar/openFilterConditionMenu',
          'dealsTableToolbar/openFilterConditionMenu',
          'ordersTableToolbar/openFilterConditionMenu',
          'adChannelsTableToolbar/openFilterConditionMenu',
          'adChannelsTableToolbar/openLegendMetricMenu',
        ],
        ignoredPaths: [
          'trafficAttributionTableToolbar.filterConditionMenu.anchorEl',
          'eventsTableToolbar.filterConditionMenu.anchorEl',
          'dealsTableToolbar.filterConditionMenu.anchorEl',
          'ordersTableToolbar.filterConditionMenu.anchorEl',
          'adChannelsTableToolbar.filterConditionMenu.anchorEl',
          'adChannelsTableToolbar.legendMetricMenu.anchorEl',
        ],
      },
    }).concat(apiSlice.middleware),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const persistor = persistStore(store)
