// ** React Imports
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// ** Project Imports
import { REPORT_TYPES } from '../../../constants/report.constants'
import { reportApiSlice } from '../../../api/report'
import { unauthorize } from '../../auth'
import { authApiSlice } from '../../../api/auth'

export interface ITableReportConstructorState {
  isLoadedReportConfig: boolean
  id: string | null
  name: string | null
  type: typeof REPORT_TYPES.EXTERNAL_DASHBOARD | null
  sourceURL: string | null
}

const initialState: ITableReportConstructorState = {
  isLoadedReportConfig: false,
  id: null,
  name: null,
  type: null,
  sourceURL: null,
}

export const externalDashboardConfigSlice = createSlice({
  name: 'externalDashboardConfig',
  initialState,
  reducers: {
    // ** Entire state handlers
    setInitialExternalDashboardState: () => initialState,
    setReportState: (state, action: PayloadAction<ITableReportConstructorState>) => {
      state = action.payload
    },

    // ** Report name handler
    setReportName: (state, action: PayloadAction<string>) => {
      state.name = action.payload
    },
  },
  extraReducers: (builder) => {
    // Set constructor state on unauthorize
    builder.addCase(unauthorize, (state, action) => {
      return (state = initialState)
    })

    // Set constructor state on logout
    builder.addMatcher(authApiSlice.endpoints.logoutUser.matchFulfilled, (state, action) => {
      return (state = initialState)
    })

    // Set constructor state on get report config
    builder.addMatcher(reportApiSlice.endpoints.getReportConfig.matchFulfilled, (state, action) => {
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      const { id, type, name, config } = action.payload

      if (type === REPORT_TYPES.EXTERNAL_DASHBOARD) {
        state.id = id
        state.name = name
        state.type = type
        state.sourceURL = config.source_url

        state.isLoadedReportConfig = true
      }
    })
  },
})

export default externalDashboardConfigSlice.reducer

export const { setInitialExternalDashboardState, setReportState, setReportName } =
  externalDashboardConfigSlice.actions

// Customization example: https://codesandbox.io/s/devextreme-react-grid-for-bootstrap-4-qkeyk
